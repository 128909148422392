<template>
  <v-card flat>
    <v-card-text
      v-if="error"
      class="mb-0"
    >
      <v-alert
        dense
        outlined
        border="left"
        type="error"
        class="mb-0"
      >
        {{ error.message }}
      </v-alert>
    </v-card-text>
    <v-card-text>
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="Idle Timer (in seconds)"
      >
        <v-text-field
          v-model="value.seconds"
          outlined
          type="number"
          label="Idle Timer (in seconds)"
          hint="How many seconds the plugin will wait before activating the screensaver"
          required
          :error-messages="errors"
        />
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="Screensaver Time (in seconds)"
      >
        <v-text-field
          v-model="value.screensaverActiveTime"
          outlined
          type="number"
          label="Screensaver Time (in seconds)"
          hint="How long the screensaver will be active for after closing and restarting the idle timer again. Enter 0 for always active."
          required
          :error-messages="errors"
        />
      </validation-provider>
      <m-array-form
        v-model="value.items"
        item-photo="photo"
        name="Slide"
      >
        <template #[`item.title`]="{ item }">
          {{ item.photo.src || item.photo }}
        </template>
        <template #default="{ editItem }">
          <asset-manager v-model="editItem.photo" />
        </template>
      </m-array-form>
      <validation-provider
        v-slot="{ errors }"
        rules="required"
        name="Seconds Per Slide"
      >
        <v-text-field
          v-model="value.intervalInSeconds"
          outlined
          type="number"
          label="Seconds Per Slide"
          hint="How long the slider will show each slide item"
          required
          :error-messages="errors"
        />
      </validation-provider>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MScreensaverForm',
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    error: null
  })
}
</script>
