var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[(_vm.error)?_c('v-card-text',{staticClass:"mb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","outlined":"","border":"left","type":"error"}},[_vm._v(" "+_vm._s(_vm.error.message)+" ")])],1):_vm._e(),_c('v-card-text',[_c('validation-provider',{attrs:{"rules":"required","name":"Idle Timer (in seconds)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","label":"Idle Timer (in seconds)","hint":"How many seconds the plugin will wait before activating the screensaver","required":"","error-messages":errors},model:{value:(_vm.value.seconds),callback:function ($$v) {_vm.$set(_vm.value, "seconds", $$v)},expression:"value.seconds"}})]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"Screensaver Time (in seconds)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","label":"Screensaver Time (in seconds)","hint":"How long the screensaver will be active for after closing and restarting the idle timer again. Enter 0 for always active.","required":"","error-messages":errors},model:{value:(_vm.value.screensaverActiveTime),callback:function ($$v) {_vm.$set(_vm.value, "screensaverActiveTime", $$v)},expression:"value.screensaverActiveTime"}})]}}])}),_c('m-array-form',{attrs:{"item-photo":"photo","name":"Slide"},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.photo.src || item.photo)+" ")]}},{key:"default",fn:function(ref){
var editItem = ref.editItem;
return [_c('asset-manager',{model:{value:(editItem.photo),callback:function ($$v) {_vm.$set(editItem, "photo", $$v)},expression:"editItem.photo"}})]}}],null,true),model:{value:(_vm.value.items),callback:function ($$v) {_vm.$set(_vm.value, "items", $$v)},expression:"value.items"}}),_c('validation-provider',{attrs:{"rules":"required","name":"Seconds Per Slide"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","type":"number","label":"Seconds Per Slide","hint":"How long the slider will show each slide item","required":"","error-messages":errors},model:{value:(_vm.value.intervalInSeconds),callback:function ($$v) {_vm.$set(_vm.value, "intervalInSeconds", $$v)},expression:"value.intervalInSeconds"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }